import React, { useState } from "react";
import { Grid, Tabs, Tab, Typography } from "@mui/material";
import TabPanel from "../../../utils/TabPanel";
import LeadProfile from "./UserLeadProfile"; // Import the lead profile component
import UserPipelineStage from "./UserPipelineStage";

function UserLeadCard({ lead }) {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" gutterBottom>
          {lead?.firstname}'s Profile
        </Typography>
      </Grid>

      {/* Tabs */}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
          
        >
          <Tab label="Profile" value={0} />
          <Tab label="Pipeline Stage" value={1} />
        </Tabs>
      </Grid>

      {/* Profile Tab */}
      <Grid item xs={12}>
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LeadProfile lead={lead} />
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>

      {/* Pipeline Stage Tab */}
      <Grid item xs={12}>
        <TabPanel value={tabValue} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UserPipelineStage lead={lead} />
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default UserLeadCard;
