import React, { useState } from "react";
import { Grid, Tabs, Tab, Typography, Button } from "@mui/material";
import TabPanel from "../../../utils/TabPanel";
import LeadProfile from "./LeadProfile"; // Import the lead profile component
import EditLeadModal from "./EditLeadModal"; // Assume a modal for editing lead details

function LeadCard({ lead }) {
  const [tabValue, setTabValue] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false); // State to manage modal visibility
  const [leadData, setLeadData] = useState(lead);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleLeadUpdate = (updatedLead) => {
    setLeadData(updatedLead); 
  };

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" gutterBottom>
        {lead?.firstname}'s Profile
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ 
            
            backgroundColor: 'blue',
            color: 'white',
            '&:hover': {
              backgroundColor: 'yellow',
              color: 'black'
            },borderRadius: '8px', // Example additional style
            padding: '8px 16px'
          }}
          onClick={handleModalOpen}
        >
          Update Lead
        </Button>
      </Grid>

      {/* Tabs */}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
        >
          <Tab label="Profile" value={0} />
          <Tab label="Pipeline Stage" value={1} />
        </Tabs>
      </Grid>

      {/* Profile Tab */}
      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LeadProfile lead={leadData} /> 
          </Grid>
        </Grid>
      </TabPanel>

      {/* Pipeline Stage Tab */}
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Add relevant information about the pipeline stage here */}
            <Typography variant="h6">Pipeline Stage Information</Typography>
            <Typography variant="body1">
              Current Stage: {leadData?.pipelinestage || "Not Available"}
            </Typography>
            {/* You can add more fields or display a history of pipeline stages */}
          </Grid>
        </Grid>
      </TabPanel>

      {/* Update Lead Modal */}
      <EditLeadModal
        open={isModalOpen}
        onClose={handleModalClose}
        lead={leadData}
        onUpdate={handleLeadUpdate} 
      />
    </Grid>
  );
}

export default LeadCard;
