import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  Box,
  TableSortLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { dataFetch, dataUpdate } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import UserLeadCard from "./UserLeadCard";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../utils/theme";
import toast from "react-hot-toast";
import UserAddLeadModal from "./UserAddLeadModal";
import { getDateTimeSync } from "../../../utils/DateTimeHelper";

const stages = [
  "New",
  "Lost",
  "Inactive",
  "Converted",
  "Contacted",
  "Reopened",
];

const UserLeadList = () => {
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedLead, setSelectedLead] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { token } = useAuth();
  const [open, setOpen] = useState(false);
  const [currentLeadId, setCurrentLeadId] = useState(null);
  const [comment, setComment] = useState("");
  const [pipelineStage, setPipelineStage] = useState("");

  useEffect(() => {
    const fetchLeads = async () => {
      setLoadingData(true);
      try {
        const skip = (page - 1) * rowsPerPage;
        const url = `lead/myleads/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
        const response = await dataFetch(url, token);

        if (response.status === 200) {
          setLeads(response.data.data);
          setTotalLeads(response.data.total);
        } else {
          console.error(`Error fetching leads: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      } finally {
        setLoadingData(false);
      }
    };

    fetchLeads();
  }, [page, sortField, sortOrder, token]);


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };


  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const addLead = (newLead) => {
    setLeads((prevLeads) => [...prevLeads, newLead]);
    setTotalLeads((prevTotal) => prevTotal + 1);
  };
  const handleLeadClick = async (leadId) => {
    setLoadingData(true);
    try {
      const url = `lead/user/${leadId}`;
      const response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedLead(response.data.data);
      } else {
        console.error(`Error fetching lead details: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching lead details:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalLeads / rowsPerPage);

  const updatePipelineStage = async (leadId, newPipelineStage, comment) => {
    setLoadingData(true);
    try {
      const url = `/lead/${leadId}/pipelinestage`;
      const response = await dataUpdate(
        url,
        { pipelinestage: newPipelineStage, pipelineComment: comment },
        token
      );
  
      if (response.status === 200) {
        toast.success("Pipeline stage updated successfully!");
  
        // Update the leads state immutably
        setLeads((prevLeads) =>
          prevLeads.map((lead) =>
            lead._id === leadId
              ? {
                  ...lead,
                  pipelinestage: newPipelineStage,
                  pipelineComment: lead.pipelineComment
                    ? [...lead.pipelineComment, comment] // Append new comment
                    : [comment], // Initialize if undefined
                }
              : lead
          )
        );
  
        console.log("Pipeline stage updated successfully for lead ID:", leadId);
      } else {
        toast.error("Failed to update pipeline stage!");
        console.error(`Error updating pipeline stage: ${response.status}`);
      }
    } catch (error) {
      toast.error("An error occurred while updating pipeline stage.");
      console.error("Error updating pipeline stage:", error);
    } finally {
      setLoadingData(false);
  
      // Clear form state after submission
      setComment("");
      setPipelineStage("");
  
      // Close modal after submission
      handleClose();
    }
  };
  
  const handleOpen = (leadId) => {
    setCurrentLeadId(leadId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setComment(""); // Reset comment state when modal closes
    setPipelineStage(""); // Reset pipeline stage state when modal closes
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      {selectedLead ? (
        <Grid item xs={12}>
          <UserLeadCard lead={selectedLead} token={token} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h5" gutterBottom>
             All Lead List  - Page {page}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: 'blue',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'yellow',
                    color: 'black'
                  },
                  borderRadius: '8px',
                  padding: '8px 16px'
                }}
                onClick={handleOpenModal}
              >
                Add Lead
              </Button>
          </Grid>
          <Grid item xs={12}>
            {loadingData ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "50vh" }}>
                <CircularProgress />
              </Grid>
            ) : leads.length === 0 ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "50vh" }}>
                <Typography variant="h6" color="textSecondary">
                  No Lead has been Assigned! Kindly Contact Admin.
                </Typography>
              </Grid>
            ) : (
              <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {["name", "email", "date", "pipelinestage", "phonenumber"].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: "bold",
                            padding: { xs: "8px", md: "16px" },
                            textAlign: "center",
                            backgroundColor: colors.primary[500],
                            color: "white",
                            "&:hover": {
                              backgroundColor: "white",
                              color: "black",
                            },
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : "asc"}
                            onClick={() => handleSortRequest(header)}
                            sx={{ textAlign: "center" }}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leads.map((lead) => (
                      <TableRow
                        key={lead._id}
                        onClick={() => handleLeadClick(lead._id)}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: colors.primary[500],
                            color: "white",
                            "& td": {
                              color: "white",
                              backgroundColor: colors.primary[500],
                            },
                          },
                        }}
                      >
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                          {lead?.firstname}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{lead?.email}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{getDateTimeSync(lead?.date)}</TableCell>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent row click event
                              handleOpen(lead._id); // Open the comment modal
                            }}
                          >
                            +
                          </Button>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{lead?.phonenumber}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{
                  "& .MuiPaginationItem-root": {
                    fontSize: { xs: "0.8rem", md: "1rem" },
                    margin: "0 4px",
                  },
                  "& .MuiPaginationItem-ellipsis": { display: "none" },
                  "& .MuiPaginationItem-page.Mui-selected": {
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  },
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>
        </>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Pipeline Stage</DialogTitle>
        <DialogContent>
          <Select
            value={pipelineStage}
            onChange={(e) => setPipelineStage(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ mb: 2 }}
          >
            <MenuItem value="" disabled>Select Pipeline Stage</MenuItem>
            {stages.map((stage) => (
              <MenuItem key={stage} value={stage}>
                {stage}
              </MenuItem>
            ))}
          </Select>

          <TextField
            autoFocus
            margin="dense"
            label="Comment"
            type="text"
            fullWidth
            variant="outlined"
            value={comment}
            sx={{ width: '100%' }}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (currentLeadId && pipelineStage) {
                updatePipelineStage(currentLeadId, pipelineStage, comment); // Submit both pipeline stage and comment
              }
            }}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <UserAddLeadModal open={openModal} onClose={handleCloseModal} onAddLead={addLead} />
    </Grid>
  );
};

export default UserLeadList;
