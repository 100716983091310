import React from "react";
import { Grid, useTheme } from "@mui/material";

import { tokens } from "../../../utils/theme";
import UserLeadSearch from "./UserLeadSearch";
import UserLeadList from "./UserLeadList";
import NewLeadList from "./NewLeadList";

const UserLeadsDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid
      container
      spacing={2} 
      sx={{ padding: 3, backgroundColor: "#ffffff", minHeight: "50vh" }}
    >
      <Grid item xs={12}>
        <UserLeadSearch/>
      </Grid>

      <Grid item xs={12}>
        <NewLeadList/>
      </Grid>
      <Grid item xs={12} sx={{marginTop:2}}>
        <UserLeadList />
      </Grid>
    </Grid>
  );
};

export default UserLeadsDashboard;
