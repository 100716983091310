import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  Button,
  TableSortLabel,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { dataDelete, dataFetch } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import LeadCard from "./LeadCard";
import AddLeadModal from "./AddLeadModal";
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from "@emotion/react";
import { tokens } from "../../../utils/theme";
import toast from "react-hot-toast";
import UploadLeadsModal from "./UploadLeadsModal";
import { getDateTimeSync } from "../../../utils/DateTimeHelper";

const MgrNewLeadList = () => {
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedLead, setSelectedLead] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [leadIdToDelete, setLeadIdToDelete] = useState(null);
  const { token } = useAuth();
  const [uploadOpen, setUploadOpen] = useState(false);
  
  
  const fetchLeads = async () => {
    setLoadingData(true);
    try {
      const skip = (page - 1) * rowsPerPage; 
      let url = `lead/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}&pipelinestage=New`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setLeads(response.data.data);
        setTotalLeads(response.data.total);
      } else {
        console.error(`Error fetching leads: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching leads:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };
  useEffect(() => {
   

    fetchLeads();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleOpenUploadModal = () => {
    setUploadOpen(true);
  };

  const handleCloseUploadModal = () => {
    setUploadOpen(false);
  };

  const addLead = (newLead) => {
    setLeads((prevLeads) => [...prevLeads, newLead]);
    setTotalLeads((prevTotal) => prevTotal + 1);
  };

  const handleLeadClick = async (leadId) => {
    setLoadingData(true);
    try {
      let url = `lead/${leadId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedLead(response.data.data);
      } else {
        console.error(`Error fetching lead details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching lead details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  const handleOpenDeleteDialog = (leadId) => {
    setLeadIdToDelete(leadId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setLeadIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (leadIdToDelete) {
      setLoadingData(true);
      try {
        const url = `lead/${leadIdToDelete}`;
        const response = await dataDelete(url, token);
        
        if (response.status === 200) {
          setLeads(leads.filter(lead => lead._id !== leadIdToDelete));
          setTotalLeads(prev => prev - 1);
          toast.success('Lead deleted successfully');
        } else {
          console.error(`Error deleting lead: ${response.status}`);
          toast.error('Error deleting lead');
        }
      } catch (error) {
        console.error('Error deleting lead:', error);
        toast.error('Error deleting lead');
      } finally {
        setLoadingData(false);
        handleCloseDeleteDialog(); // Close dialog after deletion
      }
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalLeads / rowsPerPage);

  return (
    <Grid container spacing={2} justifyContent="center">
      {selectedLead ? (
        <Grid item xs={12}>
          <LeadCard lead={selectedLead} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5" gutterBottom>
             New Lead Dashboard - Page {page}
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <Button 
                variant="contained" 
                color="primary" 
                sx={{ 
                  backgroundColor: 'blue',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'yellow',
                    color: 'black'
                  },
                  borderRadius: '8px', // Example additional style
                  padding: '8px 16px'
                }}
                onClick={handleOpenUploadModal}
              >
                Upload File
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: 'blue',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'yellow',
                    color: 'black'
                  },
                  borderRadius: '8px',
                  padding: '8px 16px'
                }}
                onClick={handleOpenModal}
              >
                Add Lead
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {loadingData ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                <CircularProgress />
              </Grid>
            ) : (
              <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {["name", "email", "Date", "pipelinestage", "phonenumber", "delete"].map(header => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: 'bold',
                            padding: { xs: '8px', md: '16px' },
                            textAlign: 'center',
                            backgroundColor: colors.primary[500],
                            color: 'white',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: 'black',
                            }
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : 'asc'}
                            onClick={() => handleSortRequest(header)}
                            sx={{ textAlign: 'center' }}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leads.map(lead => (
                      <TableRow
                        key={lead._id}
                        onClick={() => handleLeadClick(lead._id)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: colors.primary[500],
                            color: 'white',
                            '& td': {
                              color: 'white',
                              backgroundColor: colors.primary[500],
                            }
                          }
                        }}
                      >
                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{lead?.firstname}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{lead?.email}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{getDateTimeSync(lead?.date)}</TableCell>
                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}><b>{lead?.pipelinestage.toUpperCase()}</b></TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{lead?.phonenumber}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenDeleteDialog(lead._id);
                            }}
                            sx={{
                              backgroundColor: 'black',
                              color: 'white',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: 'red',
                              }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{ 
                  '& .MuiPaginationItem-root': { 
                    fontSize: { xs: '0.8rem', md: '1rem' },
                    margin: '0 4px'
                  },
                  '& .MuiPaginationItem-ellipsis': { display: 'none' },
                  '& .MuiPaginationItem-page.Mui-selected': {
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    }
                  }
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>

          {/* Delete Confirmation Dialog */}
          <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            fullWidth
            maxWidth="xs"
            PaperProps={{
              sx: {
                borderRadius: 10,
                padding: 2,
                minWidth: '300px',
                boxShadow: 3,
              },
            }}
          >
            <DialogTitle sx={{ textAlign: 'center', paddingBottom: 2 }}>
              <Typography variant="h6">Delete Confirmation</Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: 2 }}>
              <DialogContentText sx={{ textAlign: 'center', marginBottom: 2 }}>
                Are you sure you want to delete this lead?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
              <Button
                onClick={handleConfirmDelete}
                variant="contained"
                sx={{
                  backgroundColor: 'error.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'error.dark',
                  },
                  padding: '8px 16px',
                  marginRight: 1,
                }}
              >
                Yes
              </Button>
              <Button
                onClick={handleCloseDeleteDialog}
                variant="outlined"
                sx={{
                  color: 'blue',
                  borderColor: 'blue',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: 'blue',
                    color: 'white',
                  },
                }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
          <UploadLeadsModal open={uploadOpen} onClose={handleCloseUploadModal} onUpload={fetchLeads} />
          <AddLeadModal open={openModal} onClose={handleCloseModal} onAddLead={addLead} />
        </>
      )}
    </Grid>
  );
};

export default MgrNewLeadList;
