import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,

} from "@mui/material";
import { dataFetch, dataUpdate } from "../../../services/ApiEndPoint"; 
import { useAuth } from "../../../contexts/AuthContext";

const EditDealsModal = ({onClose, open, deals, onUpdate }) => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]); 

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);
  if(response.status===200)
  {
    setUsers(Array.isArray(response.data.data) ? response.data.data : []); 

  }
        
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);


  const validationSchema = Yup.object({
   actualdate: Yup.string().required("Please add an actual date"),
   pipelinestage: Yup.string().required("Please add a pipeline stage"),
    name: Yup.string().required("Please add a full name"),
   estimatedvalue: Yup.string().required("Please add an estimated value"),
   productorservice: Yup.string().required("Please add a product or service"),
   company: Yup.string().required("Please add a company"),
   estimatedclosuredate: Yup.string().required("Please add an estimated closure date"),
   owner: Yup.string().required("Please add an owner"),
   pipelineComment:Yup.string(),
  });

  const initialValues = {
    actualdate: deals?.actualdate || "",
    pipelinestage: deals?.pipelinestage || "",
    name: deals?.name || "",
    estimatedvalue: deals?.estimatedvalue || "",
    productorservice: deals?.productorservice || "",
    company: deals?.company || "",
    estimatedclosuredate: deals?.estimatedclosuredate || "",
    owner: deals?.owner || "",
pipelineComment: deals?.pipelineComment || "",

  
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {

      const updatedValues ={
        ...values,
        pipelineComment: values.pipelineComment.split(",").map((item) => item.trim()),
      }

      const response = await dataUpdate(
        `deals/${deals._id}`,
        values,
        token
      ); // PUT method for updating
      //console.log("Response:", response);
      if (response.status === 200) {
        toast.success("Deals updated successfully");
        onUpdate(updatedValues); 
        resetForm();
     
      } else {
        console.error("Failed to update Deals:", response.data);
        toast.error("Failed to update Deals");
      }
    } catch (error) {
      console.error(
        "Error updating Deals:",
        error.response || error.message || error
      );
      toast.error("Error updating Deals");
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  useEffect(() => {
    // Reset the form with lead data when the modal opens
    if (open) {
        initialValues.actualdate = deals?.actualdate || "";
        initialValues.pipelinestage = deals?.pipelinestage || "";
        initialValues.name = deals?.name || "";
        initialValues.estimatedvalue = deals?.estimatedvalue || "";
        initialValues.productorservice = deals?.productorservice || "";
        initialValues.company = deals?.company || "";
        initialValues.estimatedclosuredate = deals?.estimatedclosuredate || "";
        initialValues.owner = deals?.owner?._id || "";
        initialValues.pipelineComment = deals?.pipelineComment.join(", ") || "";

    }
  }, [deals, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Deals</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true} // This ensures that form is reinitialized when initialValues change
      >
        {({ touched, errors }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Field
                        name="actualdate"
                        as={TextField}
                        label="Actual Date"
                        fullWidth
                        helperText={
                        <>{errors.actualdate && touched.actualdate ? errors.actualdate : ""}</>
                        }
                        error={Boolean(errors.actualdate && touched.actualdate)}
                        />
                </Grid>
                <Grid item xs={12} md={6}>
                <Field
                    name="pipelinestage"
                    as={TextField}
                    select
                    label="Pipeline Stage"
                    fullWidth
                    helperText={touched.pipelinestage ? errors.pipelinestage : ""}
                    error={Boolean(errors.pipelinestage && touched.pipelinestage)}
                  >
                  <MenuItem value="New">New</MenuItem>
                    <MenuItem value="Lost">Lost</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Converted">Converted</MenuItem>
                    <MenuItem value="Contacted">Contacted</MenuItem>
                    <MenuItem value="Reopened">Reopened</MenuItem>
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Field
                        name="name"
                        as={TextField}
                        label="Full Name"
                        fullWidth
                        helperText={
                        <>{errors.name && touched.name ? errors.name : ""}</>
                        }
                        error={Boolean(errors.name && touched.name)}
                        />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field
                        name="estimatedvalue"
                        as={TextField}
                        label="Estimated Value"
                        fullWidth
                        helperText={
                        <>{errors.estimatedvalue && touched.estimatedvalue ? errors.estimatedvalue : ""}</>
                        }
                        error={Boolean(errors.estimatedvalue && touched.estimatedvalue)}
                        />
                </Grid>
              
                <Grid item xs={12} md={6}>
                    <Field
                        name="productorservice"
                        as={TextField}
                        label="Product or Service"
                        fullWidth
                        helperText={
                        <>{errors.productorservice && touched.productorservice ? errors.productorservice : ""}</>
                        }
                        error={Boolean(errors.productorservice && touched.productorservice)}
                        />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field
                        name="company"
                        as={TextField}
                        label="Company"
                        fullWidth
                        helperText={
                        <>{errors.company && touched.company ? errors.company : ""}</>
                        }
                        error={Boolean(errors.company && touched.company)}
                        />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Field 
                        name="estimatedclosuredate"
                        as={TextField}
                        label="Estimated Closure Date"
                        fullWidth
                        helperText={
                        <>{errors.estimatedclosuredate && touched.estimatedclosuredate ? errors.estimatedclosuredate : ""}</>
                        }
                        error={Boolean(errors.estimatedclosuredate && touched.estimatedclosuredate)}
                        />  
                </Grid>
                <Grid item xs={12} md={6}>
                <Field
                    name="owner"
                    as={TextField}
                    select
                    label="Owner"
                    fullWidth
                    helperText={touched.owner ? errors.owner : ""}
                    error={Boolean(errors.owner && touched.owner)}
                  >
                    {users.length > 0 ? (
                      users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          {user.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No users available</MenuItem>
                    )}
                  </Field>
                </Grid>
               
                <Grid item xs={12} md={6}>
                  <Field
                    name="pipelineComment"
                    as={TextField}
                    label="Pipeline Comments"
                    fullWidth
                    multiline
                    rows={4}
                    helperText={touched.pipelineComment ? errors.pipelineComment : ""}
                    error={Boolean(errors.pipelineComment && touched.pipelineComment)}
                  />
                </Grid>
                {/* Add more fields like Leaves, Attendance, etc. as needed */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Update Deals
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditDealsModal;
