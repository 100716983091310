import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { dataPost } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";

const AddCustomerModal = ({ onClose, open,onAddCustomer }) => {
  const { token } = useAuth();

  const validationSchema = Yup.object({
    name: Yup.string().required("Please add a name"),
    email: Yup.string().email("Invalid email").required("Please add an email"),
    phone: Yup.string().required("Please add a phone number"),
    address: Yup.string(),
    company: Yup.string(),
    status: Yup.string()
      .oneOf(["active", "inactive"], "Invalid status")
      .required("Please select a status"),
  });

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    address: "",
    company: "",
    status: "active",
   
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    {
      // console.log("Values:", values);
    }

    try {
      const response = await dataPost("customer/", values, token);
    
      if (response.status === 201) {
        toast.success("customer added successfully");
        onAddCustomer(response.data.data);
        resetForm();
     
      } else {
        console.error("Failed to add customer:", response.data);
        toast.error("Failed to add customer");
      }
    } catch (error) {
      console.error(
        "Error adding customer:",
        error.response || error.message || error
      );
      toast.error("Error adding customer");
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add New customer</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, values }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="name"
                    as={TextField}
                    label="Name"
                    fullWidth
                    helperText={
                      <>{errors.name && touched.name ? errors.name : ""}</>
                    }
                    error={Boolean(errors.name && touched.name)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="email"
                    as={TextField}
                    label="Email"
                    fullWidth
                    helperText={
                      <>{errors.email && touched.email ? errors.email : ""}</>
                    }
                    error={Boolean(errors.email && touched.email)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="phone"
                    as={TextField}
                    label="Phone"
                    fullWidth
                    helperText={
                      <>{errors.phone && touched.phone ? errors.phone : ""}</>
                    }
                    error={Boolean(errors.phone && touched.phone)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="status"
                    as={TextField}
                    label="Status"
                    select
                    fullWidth
                    helperText={
                      <>
                        {errors.status && touched.status ? errors.status : ""}
                      </>
                    }
                    error={Boolean(errors.status && touched.status)}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="address"
                    as={TextField}

                    label="Address"
                    fullWidth
                    helperText={
                      <>{errors.address && touched.address ? errors.address : ""}</>
                    }
                    error={Boolean(errors.address && touched.address)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="company"
                    as={TextField}
                    label="Company"
                    fullWidth
                    helperText={
                      <>{errors.company && touched.company ? errors.company : ""}</>
                    }
                    error={Boolean(errors.company && touched.company)}
                  />
                </Grid>


                {/* You can add more fields like Leaves, Attendance etc. similarly */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary" onSubmit={handleSubmit}>
                Add customer
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddCustomerModal;
