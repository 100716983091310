import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { dataFetch, dataUpdate } from "../../../services/ApiEndPoint"; // Use PUT method for updating
import { useAuth } from "../../../contexts/AuthContext";

const EditQuotationsModal = ({ onClose, open, quotations,onUpdate }) => {
  const { token } = useAuth();

  const [users, setUsers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [deals, setDeals] = useState([]);
  const [companies, setCompanies] = useState([]);

  // Fetch Users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);
        if (response.status === 200) {
          setUsers(Array.isArray(response.data.data) ? response.data.data : []);
        }
      } catch (error) {
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);

  // Fetch Contacts
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await dataFetch("/contacts/", token);

        if (response.status === 200) {
          setContacts(response.data.data);
        }
      } catch (error) {
        toast.error("Failed to fetch contacts");
      }
    };
    fetchContacts();
  }, [token]);

  // Fetch Deals
  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await dataFetch("/deals/", token);
        if (response.status === 200) {
          setDeals(response.data.data);
        }
      } catch (error) {
        toast.error("Failed to fetch deals");
      }
    };
    fetchDeals();
  }, [token]);

  // Fetch Companies
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await dataFetch("/companies/get/", token);
   
        if (response.status === 200) {
          setCompanies(response.data.data);
        }
      } catch (error) {
        toast.error("Failed to fetch companies");
      }
    };
    fetchCompanies();
  }, [token]);

  const validationSchema = Yup.object({
    quotationssummary: Yup.string().required("Required"),
    associatedcompany: Yup.string().required("Required"),
    associateddeal: Yup.string().required("Required"),
    associatedcontacts: Yup.string().required("Required"),
    quotationsstatus: Yup.string().required("Required"),
    grandtotal: Yup.string().required("Required"),
    createdby: Yup.string().required("Required"),
    createdat: Yup.string().required("Required"),
    updatedat: Yup.string().required("Required"),
  });

  const initialValues = {
    quotationssummary: quotations?.quotationssummary || "",
    associatedcompany: quotations?.associatedcompany || "",
    associateddeal: quotations?.associateddeal || "",
    associatedcontacts: quotations?.associatedcontacts || "",
    quotationsstatus: quotations?.quotationsstatus || "",
    grandtotal: quotations?.grandtotal || "",
    createdby: quotations?.createdby || "",
    createdat: quotations?.createdat || "",
    updatedat: quotations?.updatedat || "",
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await dataUpdate(
        `quotations/${quotations._id}`,
        values,
        token
      ); // PUT method for updating
      console.log("Response:", response);
      if (response.status === 200) {
        toast.success("Quotations updated successfully");
        onUpdate(values);
        resetForm();
      } else {
        console.error("Failed to update quotations:", response.message);
        toast.error("Failed to update quotations");
      }
    } catch (error) {
      console.error(
        "Error updating quotations:",
        error.response || error.message || error
      );
      toast.error("Error in updating Quotations")
  
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  useEffect(() => {
    // Reset the form with quotations data when the modal opens
    if (open) {
      initialValues.quotationssummary = quotations?.quotationssummary || "";
      initialValues.associatedcompany = quotations?.associatedcompany?._id || "";
      initialValues.associateddeal = quotations?.associateddeal?._id || "";
      initialValues.associatedcontacts = quotations?.associatedcontacts?._id || "";
      initialValues.quotationsstatus = quotations?.quotationsstatus || "";
      initialValues.grandtotal = quotations?.grandtotal || "";
      initialValues.createdby = quotations?.createdby?._id || ""
      initialValues.createdat = quotations?.createdat || "";
      initialValues.updatedat = quotations?.updatedat || "";
    }
  }, [quotations, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit quotations</DialogTitle>
      <DialogContent>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true} // This ensures that form is reinitialized when initialValues change
      >
        {({values, handleChange, errors, touched, isSubmitting }) => (
       <Form>
       <Grid container spacing={2}>
         <Grid item xs={12} md={6}>
           <TextField
             label="Quotations Summary"
             name="quotationssummary"
             fullWidth
             value={values.quotationssummary}
             onChange={handleChange}
             error={touched.quotationssummary && Boolean(errors.quotationssummary)}
             helperText={touched.quotationssummary && errors.quotationssummary}
           />
         </Grid>
         <Grid item xs={12} md={6}>
           <TextField
             select
             label="Associated Company"
             name="associatedcompany"
             fullWidth
             value={values.associatedcompany}
             onChange={handleChange}
             error={touched.associatedcompany && Boolean(errors.associatedcompany)}
             helperText={touched.associatedcompany && errors.associatedcompany}
           >
             {companies.map((company) => (
               <MenuItem key={company._id} value={company._id}>
                 {company.name}
               </MenuItem>
             ))}
           </TextField>
         </Grid>
         <Grid item xs={12} md={6}>
           <TextField
             select
             label="Associated Deal"
             name="associateddeal"
             fullWidth
             value={values.associateddeal}
             onChange={handleChange}
             error={touched.associateddeal && Boolean(errors.associateddeal)}
             helperText={touched.associateddeal && errors.associateddeal}
           >
             {deals.map((deal) => (
               <MenuItem key={deal._id} value={deal._id}>
                 {deal.name}
               </MenuItem>
             ))}
           </TextField>
         </Grid>
         <Grid item xs={12} md={6}>
           <TextField
             select
             label="Associated Contacts"
             name="associatedcontacts"
             fullWidth
             value={values.associatedcontacts}
             onChange={handleChange}
             error={touched.associatedcontacts && Boolean(errors.associatedcontacts)}
             helperText={touched.associatedcontacts && errors.associatedcontacts}
           >
             {contacts.map((contact) => (
               <MenuItem key={contact._id} value={contact._id}>
                 {contact.fullName}
               </MenuItem>
             ))}
           </TextField>
         </Grid>
         <Grid item xs={12} md={6}>
                  <TextField
                  select
                    label="Quotation Status"
                    name="quotationsstatus"
                    fullWidth
                    value={values.quotationsstatus}
                    onChange={handleChange}
                    error={touched.quotationsstatus && Boolean(errors.quotationsstatus)}
                    helperText={touched.quotationsstatus && errors.quotationsstatus}
                  >

                    <MenuItem value="Draft">Draft</MenuItem>
                    <MenuItem value="Sent">Sent</MenuItem>
                    <MenuItem value="Accepted">Accepted</MenuItem>
                    <MenuItem value="Rejected">Rejected</MenuItem>
                  </TextField>
                </Grid>
         <Grid item xs={12} md={6}>
           <TextField
             label="Grand Total"
             name="grandtotal"
             fullWidth
             value={values.grandtotal}
             onChange={handleChange}
             error={touched.grandtotal && Boolean(errors.grandtotal)}
             helperText={touched.grandtotal && errors.grandtotal}
           />
         </Grid>
         <Grid item xs={12} md={6}>
           <TextField
             select
             label="Created By"
             name="createdby"
             fullWidth
             value={values.createdby}
             onChange={handleChange}
             error={touched.createdby && Boolean(errors.createdby)}
             helperText={touched.createdby && errors.createdby}
           >
             {users.map((user) => (
               <MenuItem key={user._id} value={user._id}>
                 {user.name}
               </MenuItem>
             ))}
           </TextField>
         </Grid>
       </Grid>
                 <DialogActions>
       <Button onClick={onClose} color="secondary">
         Cancel
        </Button>
      <Button type="submit" color="primary">
         Update Quotations
       </Button>
     </DialogActions>
     </Form>
        )}
      </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditQuotationsModal;
