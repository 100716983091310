import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
} from "@mui/material";
import { dataPost, dataFetch } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import {timezones,countries} from "../../../utils/timezone";

const AddMeetingsModal = ({ onClose, open, onAddMeetings }) => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);

        setUsers(Array.isArray(response.data.data) ? response.data.data : []);
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);

  const validationSchema = Yup.object({
    meetingName: Yup.string().required("Required"),
    meetingDuration: Yup.string().required("Required"),
    meetingDate: Yup.date().required("Required").nullable(),
    meetingTime: Yup.string().required("Required"),
    meetingTitle: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    timezone: Yup.string().required("Required"),
    participants: Yup.array().min(1, "At least one participant is required"),
    meetingLink: Yup.string()
      .url("Invalid URL")
      .required("Meeting link is required"),
    agenda: Yup.string(),
    notes: Yup.string(),
    owner: Yup.string().required("Required"),
  });

  const initialValues = {
    meetingName: "",
    meetingDuration: "",
    meetingDate: "",
    meetingTime: "",
    meetingTitle: "",
    country: "",
    timezone: "",
    participants: [],
    meetingLink: "",
    agenda: "",
    notes: "",
    owner: "",
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await dataPost("meetings/", values, token);
      if (response.status === 201) {
        toast.success("Meeting added successfully");
        onAddMeetings(response.data.data);
        resetForm();
      } else {
        console.error("Failed to add meeting:", response.data);
        toast.error("Failed to add meeting");
      }
    } catch (error) {
      console.error(
        "Error adding meeting:",
        error.response || error.message || error
      );
      toast.error("Error adding meeting");
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allUserIds = users.map((user) => user._id);
      setSelectedParticipants(allUserIds);
    } else {
      setSelectedParticipants([]);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add New Meeting</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="meetingName"
                    as={TextField}
                    label="Meeting Name"
                    fullWidth
                    helperText={
                      errors.meetingName && touched.meetingName
                        ? errors.meetingName
                        : ""
                    }
                    error={Boolean(errors.meetingName && touched.meetingName)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="meetingDuration"
                    as={TextField}
                    label="Meeting Duration"
                    fullWidth
                    helperText={
                      errors.meetingDuration && touched.meetingDuration
                        ? errors.meetingDuration
                        : ""
                    }
                    error={Boolean(
                      errors.meetingDuration && touched.meetingDuration
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="meetingDate"
                    type="date"
                    as={TextField}
                    label="Meeting Date"
                    fullWidth
                    helperText={
                      errors.meetingDate && touched.meetingDate
                        ? errors.meetingDate
                        : ""
                    }
                    error={Boolean(errors.meetingDate && touched.meetingDate)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="meetingTime"
                    type="time"
                    as={TextField}
                    label="Meeting Time"
                    fullWidth
                    helperText={
                      errors.meetingTime && touched.meetingTime
                        ? errors.meetingTime
                        : ""
                    }
                    error={Boolean(errors.meetingTime && touched.meetingTime)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="meetingTitle"
                    as={TextField}
                    label="Meeting Title"
                    fullWidth
                    helperText={
                      errors.meetingTitle && touched.meetingTitle
                        ? errors.meetingTitle
                        : ""
                    }
                    error={Boolean(errors.meetingTitle && touched.meetingTitle)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
  <FormControl fullWidth>
    <InputLabel id="Select-Country">Country</InputLabel>
    <Field
      name="country"
      as={Select}
      labelId="Select-Country"
      fullWidth
      onChange={(event) => {
        setFieldValue("country", event.target.value); // Correctly sets the country code in Formik state
      }}
    >
      {countries.map((c) => (
        <MenuItem key={c.code} value={c.code}> {/* Use c.code as value */}
          {`${c.code} - ${c.name}`}
        </MenuItem>
      ))}
    </Field>
    {errors.country && touched.country && (
      <div style={{ color: "red", marginTop: "4px" }}>
        {errors.country}
      </div>
    )}
  </FormControl>
</Grid>


                <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <InputLabel id="timezone-label">Time Zone</InputLabel>
                    <Field
                      name="timezone"
                      as={Select}
                      labelId="timezone-label"
                      fullWidth
                      onChange={(event) => {
                        setFieldValue("timezone", event.target.value);
                      }}
                    >
                      {timezones.map((tz) => (
                        <MenuItem key={tz.value} value={tz.value}>
                          {`${tz.label} (UTC ${tz.offset})`} {/* Display offset */}
                        </MenuItem>
                      ))}
                    </Field>
                    {errors.timezone && touched.timezone && (
                      <div style={{ color: "red", marginTop: "4px" }}>
                        {errors.timezone}
                      </div>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="owner"
                    as={TextField}
                    select
                    label="Owner"
                    fullWidth
                    helperText={touched.owner ? errors.owner : ""}
                    error={Boolean(errors.owner && touched.owner)}
                  >
                    {users.length > 0 ? (
                      users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          {user.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No users available</MenuItem>
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="participants-label">
                      Participants
                    </InputLabel>
                    <Select
                      labelId="participants-label"
                      multiple
                      value={selectedParticipants}
                      onChange={(event) => {
                        setSelectedParticipants(event.target.value);
                        setFieldValue("participants", event.target.value);
                      }}
                      renderValue={(selected) => {
                        const selectedUsers = users.filter((user) =>
                          selected.includes(user._id)
                        );
                        return selectedUsers
                          .map((user) => user.name)
                          .join(", ");
                      }}
                    >
                      <MenuItem>
                        <Checkbox
                          checked={selectedParticipants.length === users.length}
                          onChange={handleSelectAll}
                        />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          <Checkbox
                            checked={
                              selectedParticipants.indexOf(user._id) > -1
                            }
                          />
                          <ListItemText primary={user.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.participants && touched.participants && (
                      <div style={{ color: "red", marginTop: "4px" }}>
                        {errors.participants}
                      </div>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="meetingLink"
                    as={TextField}
                    label="Meeting Link"
                    fullWidth
                    helperText={
                      errors.meetingLink && touched.meetingLink
                        ? errors.meetingLink
                        : ""
                    }
                    error={Boolean(errors.meetingLink && touched.meetingLink)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="agenda"
                    as={TextField}
                    label="Agenda"
                    fullWidth
                    multiline
                    rows={2}
                    helperText={
                      errors.agenda && touched.agenda ? errors.agenda : ""
                    }
                    error={Boolean(errors.agenda && touched.agenda)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="notes"
                    as={TextField}
                    label="Notes"
                    fullWidth
                    multiline
                    rows={2}
                    helperText={
                      errors.notes && touched.notes ? errors.notes : ""
                    }
                    error={Boolean(errors.notes && touched.notes)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add Meeting
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddMeetingsModal;
