import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  Button,
  TableSortLabel,
  IconButton,
  Box,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { dataFetch, dataPost } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import LeadCard from "./LeadCard";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../utils/theme";
import toast from "react-hot-toast";
import { getDateTimeSync } from "../../../utils/DateTimeHelper";

const AssignLeadList = () => {
  const [leads, setLeads] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedLead, setSelectedLead] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const { token } = useAuth();
  const [users, setUsers] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false); // Modal state for delete confirmation
  const [deleteLeads, setDeleteLeads] = useState([]); // Leads to be deleted

  // Fetching users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);
        setUsers(Array.isArray(response.data.data) ? response.data.data : []);
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);

  // Fetching leads
  const fetchLeads = async () => {
    setLoadingData(true);
    try {
      const skip = (page - 1) * rowsPerPage;
      let url = `lead/unassigned?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setLeads(response.data.data);
        setTotalLeads(response.data.total);
      } else {
        console.error(`Error fetching leads: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  // Handling selection of leads
  const handleSelectLead = (leadId) => {
    setSelectedLeads((prevSelected) => {
      if (prevSelected.includes(leadId)) {
        return prevSelected.filter((id) => id !== leadId); // Deselect lead
      } else {
        return [...prevSelected, leadId]; // Select lead
      }
    });
    // Log after selection change
    setTimeout(() => {
      console.log("Updated Selected Leads:", selectedLeads);
    }, 0);
  };

  // Select all leads
  const handleSelectAllLeads = (event) => {
    if (event.target.checked) {
      setSelectedLeads(leads.map((lead) => lead._id));
    } else {
      setSelectedLeads([]);
    }
    setTimeout(() => {
      console.log("Select All - Selected Leads:", selectedLeads);
    }, 0);
  };

  // Open and close Assign Modal
  const openAssignModal = () => setIsAssignModalOpen(true);
  const closeAssignModal = () => {
    setIsAssignModalOpen(false);
    setSelectedUsers([]);
  };

  const handleUserSelect = (event) => {
    const { value } = event.target;
    setSelectedUsers(typeof value === "string" ? value.split(",") : value);
  };

 
 // Handle Assign Leads action
 const handleAssignLeads = async () => {
    if (selectedLeads.length === 0 || selectedUsers.length === 0) {
      toast.error("Please select at least one lead and one user to assign.");
      return;
    }

    try {
      const response = await dataPost("/lead/assign-leads", {
        leadIds: selectedLeads, // Array of lead IDs
        assignedUsers: selectedUsers, // Array of user IDs
      },token);

      if (response.data.success) {
        toast.success("Leads assigned successfully!");
        setIsAssignModalOpen(false);
        setSelectedLeads([]); // Reset selected leads after assignment
        fetchLeads(); // Refresh lead data after assignment
      } else {
        toast.error(response.data.message || "Failed to assign leads.");
      }
    } catch (error) {
      console.error("Error assigning leads:", error);
      toast.error("Error assigning leads");
    }
};

// Handle Delete Leads action
const handleDeleteLeads = async () => {
  if (selectedLeads.length === 0) {
    toast.error("Please select at least one lead to delete.");
    return;
  }
  setDeleteLeads(selectedLeads); // Set the leads to be deleted
  setIsDeleteConfirmationOpen(true); // Open the delete confirmation dialog
};

// Confirm Deletion
const confirmDeleteLeads = async () => {
  try {
    const response = await dataPost(
      "/lead/many",
      { leadIds: deleteLeads }, // Pass selected leads as payload
      token
    );

    if (response.data.success) {
      toast.success("Selected leads deleted successfully!");
      setSelectedLeads([]); // Reset selected leads
      setIsDeleteConfirmationOpen(false); // Close the delete confirmation modal
      fetchLeads(); // Refresh leads
    } else {
      toast.error(response.data.message || "Failed to delete leads.");
    }
  } catch (error) {
    console.error("Error deleting leads:", error);
    toast.error("Error deleting leads");
  }
};

// Close Delete Modal
const closeDeleteModal = () => {
  setIsDeleteConfirmationOpen(false);
  setDeleteLeads([]); // Clear leads to be deleted
};
  

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalLeads / rowsPerPage);

  return (
    <Grid container spacing={2} justifyContent="center">
      {selectedLead ? (
        <Grid item xs={12}>
          <LeadCard lead={selectedLead} />
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" gutterBottom>
              Lead Dashboard - Page {page}
            </Typography>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={openAssignModal}
                disabled={selectedLeads.length === 0}
                sx={{
                  backgroundColor: "blue",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "yellow",
                    color: "black",
                  },
                  borderRadius: "8px",
                  padding: "8px 16px",
                }}
              >
                Assign Lead
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDeleteLeads}
                disabled={selectedLeads.length === 0}
                sx={{
                  backgroundColor: "red",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "darkred",
                    color: "black",
                  },
                  borderRadius: "8px",
                  padding: "8px 16px",
                }}
              >
                Delete Lead
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            {loadingData ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "50vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <TableContainer
                component={Paper}
                sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedLeads.length === leads.length}
                          onChange={handleSelectAllLeads}
                          inputProps={{ "aria-label": "select all leads" }}
                        />
                      </TableCell>
                      {[
                        "name",
                        "email",
                        "date",
                        "phonenumber",
                   
                      ].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: "bold",
                            padding: { xs: "8px", md: "16px" },
                            textAlign: "center",
                            backgroundColor: colors.primary[500],
                            color: "white",
                            "&:hover": {
                              backgroundColor: "white",
                              color: "black",
                            },
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : "asc"}
                            onClick={() => handleSortRequest(header)}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leads.map((lead) => (
                      <TableRow
                        key={lead._id}
                        sx={{
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "#e3f2fd" },
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedLeads.includes(lead._id)}
                            onChange={() => handleSelectLead(lead._id)}
                          />
                        </TableCell>
                        <TableCell>{lead.firstname}</TableCell>
                        <TableCell>{lead.email}</TableCell>
                        <TableCell>{getDateTimeSync(lead?.date)}</TableCell>
                        <TableCell>{lead.phonenumber}</TableCell>
                     
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{ 
                  '& .MuiPaginationItem-root': { 
                    fontSize: { xs: '0.8rem', md: '1rem' },
                    margin: '0 4px'
                  },
                  '& .MuiPaginationItem-ellipsis': { display: 'none' },
                  '& .MuiPaginationItem-page.Mui-selected': {
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    }
                  }
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>
          </Grid>

          {/* Delete Confirmation Modal */}
      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={closeDeleteModal}
        aria-labelledby="delete-lead-dialog-title"
      >
        <DialogTitle id="delete-lead-dialog-title">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          Are you sure you want to delete the selected lead(s)?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirmDeleteLeads}
            color="secondary"
            variant="contained"
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>

          {/* Assign Modal */}
          <Dialog open={isAssignModalOpen} onClose={closeAssignModal}>
            <DialogTitle>Assign Leads</DialogTitle>
            <DialogContent>
              <FormControl fullWidth margin="normal">
                <InputLabel>Users</InputLabel>
                <Select
                  multiple
                  value={selectedUsers}
                  onChange={handleUserSelect}
                  renderValue={(selected) => {
                    // Get the names of the selected users
                    const selectedUserNames = users
                      .filter((user) => selected.includes(user._id)) // Find users whose IDs match the selected ones
                      .map((user) => user.name); // Extract the user names
                    return selectedUserNames.join(", "); // Join the names with commas
                  }}
                >
                  {users.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      <Checkbox
                        checked={selectedUsers.indexOf(user._id) > -1}
                      />
                      <ListItemText primary={user.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeAssignModal} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAssignLeads} color="primary">
                Assign
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Grid>
  );
};

export default AssignLeadList;
